import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./core/components/Layout";
import PrivacyPolicyPage from "./pages/privacy-policy";
import TermsAndConditionsPage from "./pages/terms-and-conditions";
import AboutUsPage from "./pages/About";
import { s } from "./core/components/Home/utils";
import RequestAccess from "./pages/RequestAccess";
import RequestAccessPage from "./pages/RequestAccess";
import RequestAccessEvCharging from "./pages/RequestAccessEvCharging";
import ThankYou from "./core/components/RequestAccess/ThankYou";
import ThankYouEvCharging from "./core/components/RequestAccessEvCharging/ThankYou";
import { useEffect } from "react";
import Conflunece from "./pages/Confluence";
import TradedVC from "./pages/tradedvc";
import Hotjar from '@hotjar/browser';
import CampaignRequestAccess from "./core/components/dyanamicCampaign/RequestAccess";
import CampaignThankYou from "./core/components/dyanamicCampaign/ThankYou";
import CareersPage from "./pages/Career";
import RequestConference from "./pages/RequestConference";

const REACT_APP_HOTJAR_SITEID = process.env.REACT_APP_HOTJAR_SITEID;

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const source = urlParams.get("source");

  useEffect(() => {
    if (!source) {
      return;
    } else if (source) {
      sessionStorage.setItem("source", JSON.stringify(source));
    }
  }, [source]);

  useEffect(() => {
    const siteId = REACT_APP_HOTJAR_SITEID;
    const hotjarVersion = 6;
    if (siteId) {
      Hotjar.init(Number(siteId), hotjarVersion);
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/privacy-policy"
          element={
            <Layout landing={true} className={s.heroPaddings}>
              <PrivacyPolicyPage />
            </Layout>
          }
        />
         <Route
          path="/careers"
          element={
            <Layout landing={true} className={s.heroPaddings}>
              <CareersPage />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout landing={true} className={s.heroPaddings}>
              <TermsAndConditionsPage />
            </Layout>
          }
        />
        <Route
          path="/about-us"
          element={
            <Layout landing={false} className={s.heroPaddings}>
              <AboutUsPage />
            </Layout>
          }
        />
        <Route path="/request-access" element={<RequestAccessPage />} />
        <Route path="/request-access/download/:source" element={<RequestAccessEvCharging />} /> 
        <Route path="/thank-you/download/:source" element={<ThankYouEvCharging />} />
        <Route path="/request-access/campaign/:id" element={<CampaignRequestAccess />} /> 
        <Route path="/thank-you/campaign/:id" element={<CampaignThankYou />} />
        <Route path="/confluence" element={<Conflunece />} />
        <Route path="/tradedvc" element={<TradedVC />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/request-conference" element={<RequestConference />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
