import React, { useState, useEffect } from "react";
import { downloadReport, s } from "../Home/utils";
import Navbar from "../Navbar";
import Paragraph from "../Paragraph";
import Heading from "../Heading";
import "./Form.scss";
import useDebounce from "../../hooks/useDebounce";
import { emailsBlock } from "../../utils/blackListEmails";
import { SubmitHandler, useForm } from "react-hook-form";
import { Api } from "../../hooks/Api";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { industryList } from "../../utils/constants";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Theme, useTheme } from "@mui/material/styles";
import CalendlyWidget from "../wokelo_calendly";
import { countryCodes } from "../../utils/countryCodes";
import "/node_modules/flag-icons/css/flag-icons.min.css";

type Props = {};

type Inputs = {
  username: string;
  firstname: string;
  lastname: string;
  organisation: string;
  usecase: string;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CountryMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Form = (props: Props) => {
  const [username, setUsername] = useState<string | null>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [organisation, setOrganisation] = useState<string>("");
  const [industry, setIndustry] = useState<string | null>(null);
  const [usecase, setUsecase] = useState<string>("");
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const [modalCalendly, setModalCalendly] = useState(false);
  const [countryCode, setCountryCode] = useState<string>("US");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [userDetails, setUserDetails] = useState({})

  const sessionSource = JSON.parse(sessionStorage.getItem("source") as string);
  const source = location?.href?.split('/').pop() || ''

  
  const handleCheckBox = (event) => {
    setCheckBox(event.target.checked);
  };
  const { handleSubmit } = useForm<Inputs>();

  const debouncedEmail = useDebounce(username, 500);

  useEffect(() => {
    setUsername(email);
  }, [email]);

  function handleSkip(scheduled?: boolean) {
    // if (scheduled) {
      navigate("/thank-you");
    // }
    setModalCalendly(false);
  }

  const onSubmit: SubmitHandler<Inputs> = async () => {      
    setFormError("");
    setLoading(true);

    const selectedC = countryCodes.find((e)=>e.code===countryCode)?.dial_code

    const payload = {
      username: username,
      firstname: firstname,
      lastname: lastname,
      organisation: organisation,
      industry,
      usecase: usecase,
      source: source,
      countryCode: selectedC,
      phoneNo: phoneNo
    };
    setUserDetails(payload)

    const emailArr = username?.split("@");

    if (emailArr && emailArr?.length >= 2) {
      if (emailsBlock.includes(emailArr[1])) {
        setEmailError("Please use your corporate email address");
        setLoading(false);
      } else {
        setEmailError("");
      }
    }

    // setModalCalendly(true);
    await Api.requestAccess(payload)
      .then((res) => {
        if (res?.message) {
          if(source === 'decoding-climate-tech') { 
            window.open('https://docsend.com/view/p9n8gvd7hdag2nyc')
          }
          // setModalCalendly(true);
          // downloadReport()
          navigate(`/thank-you/download/${source}`);
          setLoading(false);
        } else {
          // setModalCalendly(true);
          setFormError("'Something Went Wrong, Please try again..'");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        // setModalCalendly(true);
        setFormError("'Something Went Wrong, Please try again..'");
        setLoading(false);
      });
  };

  useEffect(() => {
    const emailArr = debouncedEmail?.split("@");

    if (emailArr && emailArr?.length >= 2) {
      if (emailsBlock.includes(emailArr[1])) {
        setEmailError("Please use your corporate email address");
      } else {
        setEmailError("");
      }
    }
  }, [debouncedEmail]);

  const sourceTOTitle = {
    'ev-charging-stations-us': 'EV Charging Stations in US',
    'decoding-climate-tech': 'Decoding the Climate Tech Revolution'
  }

  return (
    <section
      id="landingPage"
      className={`w-full flex flex-col items-center ${s.heroPaddings} overflow-auto md:min-h-[calc(50vh)] bg-[#202020] color-animation`}
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      {/* <CalendlyWidget open={modalCalendly} setOpen={handleSkip} /> */}

      {modalCalendly ? null : <Navbar landing={false} />}
      <div className="w-full grid grid-cols-1 md:grid-cols-5 py-5 gap-3 overflow-hidden">
        <div className="flex flex-col col-span-1 md:col-span-3 text-[#fff]">
          <p className="uppercase text-sm md:text-lg font-bold text-[#E3D6C7]">
            Request report
          </p>
          <h1 className="text-4xl md:text-[44px] leading-[110%] mt-7 font-light">
            {sourceTOTitle[source]}
          </h1>
          {/* Thanks for your interest in Wokelo. Please take a moment to fill out the following information to download the full report. */}
          <p className="text-sm md:text-lg font-light leading-5 md:leading-5 mt-5 md:pr-6">
            Thanks for your interest in Wokelo. Please take a moment to fill out
            the&nbsp;
            <br className="hidden md:inline-block" />
            following information to request the full report.
          </p>
        </div>

        <div className="col-span-1 md:col-span-2 md:hidden w-full flex justify-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col px-6 bg-[#fff] rounded-3xl pt-11 pb-5 request-access-form max-w-[400px] my-8"
            style={{ border: "1px solid #E6E6E6" }}
          >
            <div className="mb-[24px]">
              <input
                type="email"
                className=" w-full text-sm data-hj-allow"
                placeholder="Business email *"
                onInput={(e) => setUsername(e.currentTarget.value)}
                value={username ?? ""}
                data-hj-allow={true}
              />
              {emailError && (
                <span
                  className=" text-red-500 text-xs"
                  style={{ lineHeight: "10px" }}
                >
                  {emailError}
                </span>
              )}
            </div>

            <div className="mb-[24px] grid grid-cols-2 gap-3">
              <div>
                <input
                  className="w-full text-sm data-hj-allow"
                  placeholder="First name *"
                  type="text"
                  onInput={(e) => setFirstname(e.currentTarget.value)}
                  value={firstname ?? ""}
                  data-hj-allow={true}
                />
              </div>
              <div>
                <input
                  className="w-full text-sm data-hj-allow"
                  type="text"
                  placeholder="Last name *"
                  onInput={(e) => setLastname(e.currentTarget.value)}
                  value={lastname ?? ""}
                  data-hj-allow={true}
                />
              </div>
            </div>

            <div className="mb-[24px]">
              <input
                className=" w-full text-sm data-hj-allow"
                placeholder="Company name *"
                type="text"
                onInput={(e) => setOrganisation(e.currentTarget.value)}
                value={organisation ?? ""}
                data-hj-allow={true}
              />
            </div>

            <div className="mb-[24px]">
              <Select
                displayEmpty
                className="w-full p-0 rounded-xl placeholder:text-gray-500 text-sm"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                input={<OutlinedInput />}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span className="text-[#717188] text-sm">Industry *</span>
                    );
                  }

                  return (
                    <span className="text-[#717188] text-sm">{selected}</span>
                  );
                }}
                MenuProps={MenuProps}
                inputProps={{
                  "aria-label": "Without label",
                  "data-hj-allow": true,
                }}
              >
                {industryList.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>



            <div className="grid grid-cols-5 mb-[24px] gap-x-3">
                <div className="col-span-2 ">
                  <div className="h-full">
                    <Select
                      
                      displayEmpty
                      className="w-full h-full p-0 rounded-xl placeholder:text-gray-500 text-sm custom-class-country"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      input={<OutlinedInput />}
                      classes={{
                        
                      }}
                      sx={{
                        width: '50px',
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span className="text-[#717188] text-sm">
                              Country
                            </span>
                          );
                        }
                        const selectedC = countryCodes.find((e)=>e.code===selected)
                        return (
                          <>
                          {selectedC && <span className={`fi fi-${selectedC?.code?.toLowerCase()} mr-3`}></span>}
                          <span className="text-[#717188] text-sm">{selectedC?.dial_code}</span>
                          </>
                        );
                      }}
                      MenuProps={{...CountryMenuProps, className: 'country-code-menu'}}
                      inputProps={{
                        "aria-label": "Without label",
                        "data-hj-allow": true,
                      }}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          <span className={`fi fi-${country.code.toLowerCase()} mr-3`}></span>
                          {country.dial_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="col-span-3 h-full">
                  <div className="h-full">
                    <input
                      className="w-full h-full text-sm"
                      placeholder="Phone number"
                      onInput={(e) => setPhoneNo(e.currentTarget.value)}
                      value={phoneNo ?? ""}
                      data-hj-allow={true}
                    />
                  </div>
                </div>

            </div>


            <div className="mb-[24px]">
              <input
                className=" w-full text-sm data-hj-allow"
                placeholder="Describe use case"
                type="text"
                onInput={(e) => setUsecase(e.currentTarget.value)}
                value={usecase ?? ""}
                data-hj-allow={true}
              />
            </div>

            <div className="flex gap-3  mb-[20px]">
              <input
                type="checkbox"
                className="h-5 w-5 rounded-md focus:ring-0 cursor-pointer data-hj-allow"
                checked={checkBox}
                style={{ border: "1.5px solid #E3E4EB", borderRadius: 5 }}
                onChange={(e) => handleCheckBox(e)}
                data-hj-allow={true}
              />
              <p className="text-[13px] text-[#1a1a1a] font-normal">
                <span>
                  By submitting this form, you agree we may use your information
                  in accordance with Wokelo&nbsp;
                </span>
                <a
                  href={"/privacy-policy"}
                  target="_blank"
                  className="text-theme underline cursor-pointer"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>

            <div className="flex justify-center">
              <button
                className="flex flex-col justify-center items-center py-5 px-[58px] rounded-[36px] bg-black text-white disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:shadow-none"
                type="submit"
                disabled={
                  !(
                    !loading &&
                    checkBox &&
                    username &&
                    firstname &&
                    lastname &&
                    organisation && industry && 
                    !emailError
                  )
                }
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "#343434",
                      fontSize: 14,
                      width: "20px !important",
                      height: "20px !important",
                    }}
                  />
                ) : (
                  <p className={"text-sm font-bold"}>Request Report</p>
                )}
              </button>
            </div>

            <div>
              {formError && (
                <span
                  className="text-red-500 text-xs"
                  style={{ lineHeight: "10px" }}
                >
                  {formError}
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
      <div
        className="absolute hidden md:flex flex-col min-h-auto w-full rounded-3xl left-0 px-[25px] lg:px-[120px] z-[2]"
        style={{ top: 120 }}
      >
        <div className="w-full grid grid-cols-1 md:grid-cols-5 py-5 gap-3 overflow-hidden">
          <div className="grid col-span-1 md:col-span-3 text-[#E3D6C7] w-full"></div>
          <div className="col-span-1 md:col-span-2 w-full px-3 flex justify-end">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col px-6 bg-[#fff] rounded-3xl pt-11 pb-5 request-access-form max-w-[400px] my-0"
              style={{ border: "1px solid #E6E6E6" }}
            >
              <div className="mb-[24px]">
                <input
                  type="email"
                  className=" w-full text-sm"
                  placeholder="Business email *"
                  onInput={(e) => setUsername(e.currentTarget.value)}
                  value={username ?? ""}
                  data-hj-allow={true}
                />
                {emailError && (
                  <span
                    className=" text-red-500 text-xs"
                    style={{ lineHeight: "10px" }}
                  >
                    {emailError}
                  </span>
                )}
              </div>

              <div className="mb-[24px] grid grid-cols-2 gap-3">
                <div>
                  <input
                    className="w-full text-sm"
                    placeholder="First name *"
                    onInput={(e) => setFirstname(e.currentTarget.value)}
                    value={firstname ?? ""}
                    data-hj-allow={true}
                  />
                </div>
                <div>
                  <input
                    className="w-full text-sm"
                    placeholder="Last name *"
                    onInput={(e) => setLastname(e.currentTarget.value)}
                    value={lastname ?? ""}
                    data-hj-allow={true}
                  />
                </div>
              </div>

              <div className="mb-[24px]">
                <input
                  className=" w-full text-sm"
                  placeholder="Company name *"
                  onInput={(e) => setOrganisation(e.currentTarget.value)}
                  value={organisation ?? ""}
                  data-hj-allow={true}
                />
              </div>

              <div className="mb-[24px]">
                <Select
                  displayEmpty
                  className="w-full p-0 rounded-xl placeholder:text-gray-500 text-sm"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  input={<OutlinedInput />}
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span className="text-[#717188] text-sm">
                          Industry *
                        </span>
                      );
                    }

                    return (
                      <span className="text-[#717188] text-sm">{selected}</span>
                    );
                  }}
                  MenuProps={MenuProps}
                  inputProps={{
                    "aria-label": "Without label",
                    "data-hj-allow": true,
                  }}
                >
                  {industryList.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="grid grid-cols-5 mb-[24px] gap-x-3">
                <div className="col-span-2 ">
                  <div className="h-full">
                    <Select
                      
                      displayEmpty
                      className="w-full h-full p-0 rounded-xl placeholder:text-gray-500 text-sm custom-class-country"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                      input={<OutlinedInput />}
                      classes={{
                        
                      }}
                      sx={{
                        width: '50px',
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span className="text-[#717188] text-sm">
                              Country
                            </span>
                          );
                        }
                        const selectedC = countryCodes.find((e)=>e.code===selected)
                        return (
                          <>
                          {selectedC && <span className={`fi fi-${selectedC?.code?.toLowerCase()} mr-3`}></span>}
                          <span className="text-[#717188] text-sm">{selectedC?.dial_code}</span>
                          </>
                        );
                      }}
                      MenuProps={{...CountryMenuProps, className: 'country-code-menu'}}
                      inputProps={{
                        "aria-label": "Without label",
                        "data-hj-allow": true,
                      }}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          <span className={`fi fi-${country.code.toLowerCase()} mr-3`}></span>
                          {country.dial_code}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="col-span-3 h-full">
                  <div className="h-full">
                    <input
                      className="w-full h-full text-sm"
                      placeholder="Phone number"
                      onInput={(e) => setPhoneNo(e.currentTarget.value)}
                      value={phoneNo ?? ""}
                      data-hj-allow={true}
                    />
                  </div>
                </div>

              </div>



              <div className="mb-[24px]">
                <input
                  className=" w-full text-sm"
                  placeholder="Describe use case"
                  onInput={(e) => setUsecase(e.currentTarget.value)}
                  value={usecase ?? ""}
                  data-hj-allow={true}
                />
              </div>

              <div className="flex gap-3  mb-[35px]">
                <input
                  type="checkbox"
                  className="h-5 w-5 rounded-md focus:ring-0 cursor-pointer"
                  checked={checkBox}
                  style={{ border: "1.5px solid #E3E4EB", borderRadius: 5 }}
                  onChange={(e) => handleCheckBox(e)}
                  data-hj-allow={true}
                />
                <p className="text-[13px] text-[#1a1a1a] font-normal">
                  <span>
                    By submitting this form, you agree we may use your
                    information in accordance with Wokelo&nbsp;
                  </span>
                  <a
                    href={"/privacy-policy"}
                    target="_blank"
                    className="text-theme underline cursor-pointer"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>

              <div className="flex justify-center mb-[20px]">
                <button
                  className="flex flex-col justify-center items-center py-5 px-[58px] rounded-[36px] bg-black text-white disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:shadow-none"
                  type="submit"
                  disabled={
                    !(
                      !loading &&
                      checkBox &&
                      username &&
                      firstname &&
                      lastname &&
                      organisation && industry &&
                      !emailError
                    )
                  }
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "#343434",
                        fontSize: 14,
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  ) : (
                    <p className={"text-sm font-bold"}>Request Report</p>
                  )}
                </button>
              </div>

              <div>
                {formError && (
                  <span
                    className="text-red-500 text-xs"
                    style={{ lineHeight: "10px" }}
                  >
                    {formError}
                  </span>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="min-h-[20vh] sm:min-h-[20vh] md:min-h-[25vh] lg:min-h-[20vh] xl:min-h-[20vh] w-full"></div>
    </section>
  );
};

export default Form;
